const swiper = new Swiper('.swiper-container', {
    direction: 'horizontal',
    loop: true,

    autoplay: {
      delay: 3500,
    },
  
    pagination: {
      el: '.swiper-pagination',
    },
  
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  
});

let sp = window.scrollY
const h = document.querySelector("header")

const add_class_on_scroll = () => h.classList.add("scrolled")
const remove_class_on_scroll = () => h.classList.remove("scrolled")

window.addEventListener('scroll', function() { 
    sp = window.scrollY;

if (sp >= 10) { add_class_on_scroll() }
else { remove_class_on_scroll() }

})

const faqs = document.querySelectorAll(".faq__el");

function toggleAccordion() {
  const itemToggle = this.getAttribute('aria-expanded');
  
  for (i = 0; i < faqs.length; i++) {
    faqs[i].setAttribute('aria-expanded', 'false');
  }
  
  if (itemToggle == 'false') {
    this.setAttribute('aria-expanded', 'true');
  }
}

faqs.forEach(faq => faq.addEventListener('click', toggleAccordion));